'use client';

import { recentlyViewedClickToEvent } from '@tracking/Schemas/RecentlyViewedClick/RecentlyViewedClickToEvent';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';

type Props = {
  vehicleId: string;
  children: React.ReactNode;
};

export const RecentlyViewedClickEvent = ({ vehicleId, children }: Props) => {
  const trackClick = () => {
    trackCustomEvent({
      event: recentlyViewedClickToEvent({ listingId: vehicleId }),
      context: [],
    });
  };

  return <span onClick={trackClick}>{children}</span>;
};
